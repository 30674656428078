import React from 'react';
import styled from '@emotion/styled';

import { Container } from '../styles/globalStyles';

// Import logo components
import ChewyLogo from '../images/logos/chewy';
import GamestopLogo from '../images/logos/gamestop';
import WhoopLogo from '../images/logos/whoop';
import LaddersLogo from '../images/logos/ladders';

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  justify-content: center;
  gap: 2rem;
  align-items: center;
  justify-items: center;
  margin: 2rem 0;
  padding: 0 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 150px);
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 300px;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const Logos = () => {
  return (
    <section className="logos">
      <Container>
        <LogoGrid>
          <WhoopLogo />
          <GamestopLogo />
          <ChewyLogo />
          <LaddersLogo />
        </LogoGrid>
      </Container>
    </section>
  );
};

export default Logos;
